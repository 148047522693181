<template>
  <div class="bgcenter">
    <headbox
      title='上门取货'
      bgColor="#39394D"
      righturl="logistics"
      class="headbox"
      :isbackperson="true"
    ></headbox>

    <!-- tab标签页 -->
    <van-tabs v-model="activeName" sticky  color="FF2D2E" title-active-color="#39394D" title-inactive-color="#8F8F9F">
       <van-tab title="寄快递" name="a" >
             <!-- 主体结构组件 -->
             <pickUp></pickUp>
       </van-tab>
       <van-tab title="查快递" name="b">
         <logistics></logistics>
       </van-tab>
    </van-tabs>

  </div>
</template>


<script >
import headbox from "@/components/head.vue";
import pickUp from './components/pickUp.vue'
import logistics from './logistics.vue'

export default{
  components:{
    headbox,
    pickUp,
    logistics
  },
  data() {
    return {
      // v-model activeName 的值为当前标签的 name  默认是a
     activeName: 'a',
    };
  },
  mounted() {
    this.activeName = this.$route.query.active?this.$route.query.active:'a'
  },
}
</script>
<style lang="scss" scoped>
// .van-tabs__wrap {
//     margin-bottom: 20px;
// }

::v-deep{
  .van-tabs{
  .van-sticky--fixed{
    z-index: 2 !important;
  }
  background-color:#F3F3F3 ;
   .van-tabs__wrap{
     .van-tabs__nav{
       z-index: 1;
     }
    height: 76px;
    .van-tabs__nav{
    margin-left: 6px;
    .van-tab {
      flex: 0;
      width: 140px !important;
      text-align: center;
      
    }
    .van-tab__text{
      font-size: 28px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 800;
      line-height: 32px;
      width: 200px !important;
    }
    .van-tabs__line {
    position: absolute;
    bottom: 2vw;
    left: 0;
    z-index: 1;
    width: 5.333vw;
    height: 0.6vw;
      background:  #39394D !important;
    border-radius: 0.4vw;
}
    .van-tabs__nav{
      .van-tab {
        width: 48px;
      }
    }
      }
   }
  .van-tabs__content{
  margin-top: 20px;
   }
  
}
}

</style>
