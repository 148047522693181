var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bgcenter" },
    [
      _c("headbox", {
        staticClass: "headbox",
        attrs: {
          title: "上门取货",
          bgColor: "#39394D",
          righturl: "logistics",
          isbackperson: true
        }
      }),
      _c(
        "van-tabs",
        {
          attrs: {
            sticky: "",
            color: "FF2D2E",
            "title-active-color": "#39394D",
            "title-inactive-color": "#8F8F9F"
          },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "van-tab",
            { attrs: { title: "寄快递", name: "a" } },
            [_c("pickUp")],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "查快递", name: "b" } },
            [_c("logistics")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }