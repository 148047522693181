var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "bg_gray" }),
      _c("div", { staticClass: "secondbox" }, [
        _c("div", { staticClass: "addressbox" }, [
          _c(
            "div",
            { staticClass: "borderBottom rel", on: { click: _vm.hrefbox } },
            [
              _c("b", { staticClass: "redBox" }, [_vm._v("寄")]),
              _vm.address_show
                ? [
                    _c("p", { staticClass: "addressTitle" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.address_list.preAddr) +
                          " " +
                          _vm._s(_vm.address_list.address) +
                          " "
                      )
                    ]),
                    _c("div", { staticClass: "addressInfo" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.address_list.name) +
                          " " +
                          _vm._s(_vm.address_list.phone) +
                          " "
                      )
                    ]),
                    _c("i", { staticClass: "el-icon-arrow-right iconbox" })
                  ]
                : [
                    _c("p", { staticClass: "addressTitle" }, [
                      _vm._v("添加取货地址")
                    ]),
                    _c("div", { staticClass: "addressInfo" }, [
                      _vm._v("请完善取货信息")
                    ]),
                    _c("i", { staticClass: "el-icon-arrow-right iconbox" })
                  ]
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "rel",
              on: {
                click: function($event) {
                  return _vm.hidePop()
                }
              }
            },
            [
              _c("b", [_vm._v("收")]),
              _c("p", { staticClass: "addressTitle" }, [
                _vm._v(_vm._s(_vm.receAddress.company))
              ]),
              _c("div", { staticClass: "addressInfo" }, [
                _vm._v(
                  _vm._s(_vm.receAddress.preAddr) +
                    _vm._s(_vm.receAddress.address)
                )
              ]),
              _c("i", { staticClass: "el-icon-arrow-right iconbox" })
            ]
          )
        ]),
        _c("div", { staticClass: "addressbox weightbox" }, [
          _c("div", { staticClass: "borderBottom rel" }, [
            _c("div", { staticClass: "addressTitle flex-between  " }, [
              _c("div", [_vm._v("预估重量")]),
              _c(
                "div",
                [
                  _c("el-input", {
                    staticClass: "weightboxinput",
                    attrs: { placeholder: "请输入货品克重", maxlength: "10" },
                    on: { input: _vm.weightinput },
                    model: {
                      value: _vm.weightbox,
                      callback: function($$v) {
                        _vm.weightbox = $$v
                      },
                      expression: "weightbox"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "rel" }, [
            _c("div", { staticClass: "addressTitle flex-between  " }, [
              _c("div", [_vm._v("备注说明")]),
              _c(
                "div",
                { staticClass: "endless" },
                [
                  _c("el-input", {
                    staticClass: "introinput",
                    attrs: { placeholder: "请快速上门取货", maxlength: "50" },
                    model: {
                      value: _vm.intro,
                      callback: function($$v) {
                        _vm.intro = $$v
                      },
                      expression: "intro"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "reminder" }, [
              _vm._v(
                " 温馨提示：下单后请保持手机通畅，顺丰工作人员将第一时间与您进行联系！ "
              )
            ])
          ]),
          _vm._m(0)
        ])
      ]),
      _c("div", { staticClass: "fixed" }, [
        _c("div", { staticClass: "flex-between boxcenter" }, [
          _c("div", [
            _c("b", [_vm._v("预计:")]),
            _vm.moneybox
              ? _c("i", { staticClass: "redcolor" }, [
                  _vm._v("¥" + _vm._s(_vm.moneybox))
                ])
              : _c("i", { staticClass: "redcolor" }, [_vm._v("¥ --")]),
            _vm.inblockTip
              ? _c(
                  "div",
                  { staticClass: "inblock rel" },
                  [
                    _vm.isclicktip
                      ? [
                          _c("div", { staticClass: "tipbox" }, [
                            _vm._v("运费将在结算时统一扣除")
                          ])
                        ]
                      : _vm._e(),
                    _c("i", {
                      staticClass: "el-icon-question icon",
                      staticStyle: { color: "#ffb606" },
                      on: { click: _vm.showtip }
                    })
                  ],
                  2
                )
              : _vm._e()
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                { staticClass: "btn_yellow", on: { click: _vm.postData } },
                [_vm._v("立即取件")]
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "van-popup",
        {
          style: { height: "30%" },
          attrs: {
            position: "bottom",
            "close-on-click-overlay": false,
            "lazy-render": "",
            "get-container": "#app"
          },
          on: { "click-overlay": _vm.overPop },
          model: {
            value: _vm.addresShow,
            callback: function($$v) {
              _vm.addresShow = $$v
            },
            expression: "addresShow"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("选择检测中心")]),
          _c(
            "van-radio-group",
            {
              model: {
                value: _vm.radio,
                callback: function($$v) {
                  _vm.radio = $$v
                },
                expression: "radio"
              }
            },
            _vm._l(_vm.Addres, function(item, index) {
              return _c(
                "div",
                { key: item.id, class: { bg: index == _vm.radio, text: true } },
                [
                  _c(
                    "van-radio",
                    {
                      attrs: {
                        name: index,
                        "checked-color": "#ff2c30",
                        "icon-size": "14px"
                      },
                      on: {
                        click: function($event) {
                          return _vm.radioClose(item)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "flex-between" }, [
                        _c("h4", [_vm._v(_vm._s(item.company))]),
                        _c("span", [_vm._v(_vm._s(item.phone))])
                      ]),
                      _c("p", [_vm._v(_vm._s(item.preAddr + item.address))])
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icons" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/kg_icon.png"), alt: "" }
      }),
      _c("img", {
        attrs: { src: require("@/assets/images/bz_icon.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }